/* GALLERY */
@import "~react-image-gallery/styles/css/image-gallery.css";


*{
    font-family: 'avertastd-regularuploadedfile', sans-serif !important;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
    margin-bottom: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
    margin-bottom: 0;
    font-family: unset;
}
p{
    margin-bottom: .3rem ;
}

button:not(.configuratore-btn):focus,button:not(.configuratore-btn):visited,button:not(.configuratore-btn):active{
    box-shadow: unset !important;
    border: 0 !important;
    border-color: unset !important;
    outline: unset !important;
    appearance: unset !important;
    /* all: initial; */
}


.menu{
    position: fixed;
    right: -100vw;
    top: 0;
    transition: all .5s;
    opacity: 0;
}
.menu.open{
    right:0;
    opacity: 1;
}


/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a:hover{
    @apply text-verde-scuro-effe;
}

*:focus {
    outline: none;
}

/* MODAL */
.ant-modal-footer{
    display: none;
}

/* COLS */
.xs, .sm, .md, .lg, .xl{
    padding: 0 !important;
}
.row{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

/* FORM */
.container-input{
    margin-bottom: 25.5px;
}
.container-input-child{
    margin-bottom: 20px;
}
.ant-input,.ant-input-number{
    outline: none;
    border:none;
    border: unset;
    background: transparent;
    transition: all 0.5s;
    border-radius: 0;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: center;
    font-size: 16px;
}

.secondary ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DDDDDD;
    opacity: 1; /* Firefox */
  }
  
.secondary :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #DDDDDD;
  }
  
.secondary ::-ms-input-placeholder { /* Microsoft Edge */
    color: #DDDDDD;
  }
.secondary .ant-input, .secondary .ant-input-number{
    @apply bg-modal-effe transition-colors;
}
.secondary  .ant-input-number input{
    @apply text-fluo-effe;
}

.ant-input,.ant-input-number{
    @apply bg-fluo-effe transition-colors;
}

.error-field .ant-input,.error-field .ant-input-number{
    @apply bg-warning-input-effe;
}
.ant-input:hover,.ant-input-number:hover{
    outline: none;
    box-shadow: none;
    border-color: transparent;
    border: 0;
}
.ant-form-item{
    margin-bottom: unset;
}
.ant-form-item-has-error .ant-input:focus, .ant-form-item-has-error .ant-input-affix-wrapper:focus, .ant-form-item-has-error .ant-input-focused, .ant-form-item-has-error .ant-input-affix-wrapper-focused,
.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover{
    border-right-width: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}
.form-label{
    font-size: 18px;
    line-height: 1.75rem;
    text-align: left;
    font-weight: 300;
    margin-bottom: 10px;
    color: #B1B1B1;
}
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
label:focus,
.btn:active,
.btn.active,
.ant-input-number-focused{
    @apply border-fluo-effe !important;
}
input:focus ~ span {
    @apply text-fluo-effe;
}
.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{
    text-align: center;
    text-transform: capitalize;
    font-size: 22px;
    font-weight: 300;
    color: #000;
}
.text-left .ant-form-item-label{
    text-align: left;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
}
.ant-input,.ant-input-number{
    @apply text-grigio-effe !important;
}
.ant-input-number{
    width: 100%;
}
.ant-input-number input{
    font-size: 16px;
    color:rgba(58,58,58,.56);
}
.ant-input-number-handler-wrap{
    display: none;
}
.ant-input-number-input{
    padding: 2.5px 9px;
    height: auto;
    max-height: 30px;
}
.discount-input .ant-input-number-input{
    max-height: unset;
}
.discount-input  .ant-form-item-control-input-content{
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.discount-input input{
    text-align: right;
    font-size: 22px;
    color:#6C6C6C;
    padding-top: 0;
    padding-bottom: 0;
}
.ant-form-item-label label{
    @apply text-xl;
}
.ant-form-item-control{
    border-radius: 2px;
}
.gridItemImage{
    background-repeat: no-repeat;
    background-position: cover;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

/* GALLERY */
.image-gallery-icon:hover{
    @apply text-fluo-effe !important;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
    @apply border-fluo-effe !important;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
    height: 60px !important;
}

.wizard-btn,.wizard-btn:hover > span{
    transition: all 0.3s;
}
.wizard-btn:hover > span{
    @apply text-white;
}
.wizard-btn:hover > svg{
    display: block;
}


.modal-content{
    padding: 25px;
}
.modal-content{
    @apply bg-white !important;
}
.modal-content p{
    margin-bottom: .3rem;
}

.warning-input > div{
    @apply border-warning-effe !important;
}
.success-input > div{
    @apply border-fluo-effe !important;
}


/*SELECT*/
.ant-select{
    width: 100%;
    outline: none !important;
}
.ant-select-selector{
    background-color: transparent !important;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
}
.ant-select-selection-placeholder{
    color: #5C5C5C;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    padding: 0 !important;
}

/* ASYNC SELECT */
.effe-async-paginate .option:last-of-type hr{
    display: none;
}


.b2btn{
    max-height: 325px;
    max-width: 325px;
    height: 80vw;
    width: 80vw;
    background-color: #C4C4C4;
    font-size: 4.5rem;
    color: white;
}

svg,button{
    transition: all .3s;
}
.infinite-scroll-component__outerdiv{
    width: 100%;
}
.infinite-scroll-component{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}


@media print {
    .hide-print  {display:none!important;}
}

@media (min-width: 992px) {
    #imgWizardInside {
        display: none !important;
    }
}

.ant-spin-dot-item{
    @apply bg-fluo-effe;
}
.spin-white .ant-spin-dot-item{
    @apply bg-white; 
}
.spin-black .ant-spin-dot-item{
    @apply bg-black-effe; 
}
.spin-green .ant-spin-dot-item{
    @apply bg-fluo-effe; 
}

/* Gallery  */
.image-gallery-image{
    max-height: unset !important;
    min-height: 300px;
    height: 100%;
    object-fit: cover !important;
}
.image-gallery, .image-gallery-content, .image-gallery-slide-wrapper,  .image-gallery-swipe,
.image-gallery-slides, .image-gallery-slide, .image-gallery-slide > div{
    height: 100%;
}  

.image-gallery{
    max-height: 500px !important;
}

.ant-tooltip{
    max-width: 60vw;
}
.ant-tooltip-inner{ 
    @apply whitespace-pre-wrap bg-verde-medioscuro-effe p-4;
}

@media screen and (max-width:768px) {
    .ant-tooltip{
        max-width: 90vw;
        right: 0;
        left:0 !important;
        margin: 0 auto;
    }
}


.cell-selected *:not(.cell-selected button) {
    color: white !important;
}


/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.anticon.ant-notification-notice-icon-success{
    @apply text-fluo-effe;
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
    margin-bottom: 0;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6C6C6C;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height:15px;
    width: 15px;
    left: 4px;
    bottom: 2.85px;
    background-color: #00FFBA;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  /* input:checked + .slider {
      background-color: #406464;
  } */
  
  /* input:focus + .slider {
    box-shadow: 0 0 1px #00FFBA;
  } */
  
  input:checked + .slider:before {
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
    bottom: 2.85px;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }


  .in-fade-in,.ex-fade-in{
     opacity:0;
     transition: opacity 0.5s;
  }
  .in-fade-out,.ex-fade-out{
    opacity:1;
    transition: opacity 0.5s;
  }

  .modal-xl{
      max-width: 1098px !important;
  }


@media screen and (max-width:768px) {
    
    .modal-dialog.modal-xl{
        padding: 0 25px;
        max-width: unset;
    }
    #logo-wizard{
        max-height: 60%;
        margin-top: 15px;
    }
    
}

/* .effe-async-paginate{
    max-width: 448px;
} */



/* HS FORM */
.hs-form {
}
.hs-form-field > label > span{
    display:none !important;
}
.hs-form .hs-form-field{
    margin-bottom: 0px;
}

.hs-form .hs-error-msg, .hs-form .hs_error_rollup{
    color:  #FD657D;
}
.hs-form a {
    color: #00E6A8 !important;
    transition: all .5s;
}
.hs-form .hs-input:not(.hs-form .hs-input[type=checkbox]){
    width: 100% !important;
    text-align: left;
    background-color: #00FFBA;
    border: 0;
    border-radius: 2px;
    outline: 0;
    transition: all .3s linear;
    -moz-appearance: textfield!important;
    outline: none;
    border: none;
    transition: all .5s;
    background-image: none;
    box-shadow: none;
    text-align: left;
    font-size: 16px;
    padding: 8.5px;
}
.hs-form input:-webkit-autofill,
.hs-form input:-webkit-autofill:hover, 
.hs-form input:-webkit-autofill:focus, 
.hs-form input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #00FFBA inset !important;
}

.hs-form textarea{
    height: unset !important;
}
.hs-error-msgs{
    font-size: 12px;
}

.hs-form select {
    -webkit-appearance: none;
    
}

  .hs-form legend,
  .legal-consent-container .hs-form-booleancheckbox-display p,
  .hs-form-required{
      font-size: 16px;
      color: #DDDDDD;
  }
  .hs-form .hs-form-booleancheckbox{
      margin-top:20px;
  }
  .hs-form .hs-form-booleancheckbox label {
    position: relative;
  }
  .hs-form .hs-form-booleancheckbox label span:not(.hs-form-required) {
  display: inline-block;
    margin-left: 40px; /* Width of the new radio select and any additional spacing on the left */
    margin-right: 16px; /* Additional spacing on the right */
  }
  /* Hide the original checkbox */
  .hs-form .hs-form-booleancheckbox label input {
    height: 20px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }
  /* Add new checkbox */
  .hs-form .hs-form-booleancheckbox label span:not(.hs-form-required)::before {
    border: 1px solid #00FFBA;
    content: "";
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    border-radius: 2px;
    background-color: #00FFBA;
  }
  /* Style new checked item */
  .hs-form .hs-form-booleancheckbox label span:not(.hs-form-required)::after {
    content: "";
    border: 3px solid;
    color:white;
    border-color: #2B2E34;
    border-left: 0;
    border-top: 0;
    height: 12px;
    left: 7.2px;
    opacity: 0;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    transition: opacity 0.2s ease-in-out;
    width: 6px;
  }
  /* Show when checked */
  .hs-form .hs-form-booleancheckbox label input:checked + span:not(.hs-form-required)::after {
    opacity: 1;
  }
  /* Style when focused */
  .hs-form .hs-form-booleancheckbox label input:focus + span:not(.hs-form-required):after {
    outline: 3px solid transparent; /* For Windows high contrast mode. */
  }




.hs-form .hs-button{
    @apply relative px-8 py-4 text-titleSectionGenerator hover:text-black-effe text-fluo-effe font-bold focus:outline-none border rounded-sm border-fluo-effe hover:bg-fluo-effe bg-transparent !important;
}
.hs-form .hs-button:hover{
    color: white;
}
.hs-form .hs-button{
    transition: all 0.5s;
}
.submitted-message p {
    margin-bottom: 0;
    color: white;
    text-align: left;
    padding: 15px 0;
    font-size: 14px;
}
.hs_submit {
    @apply my-12;
}
.hs_submit .actions{
    text-align: center;
}
.hs-form .form-columns-1,.hs-form .form-columns-2,.hs-form .form-columns-3,.hs-form .form-columns-4{
    margin: 0 auto;
}


/* GALLERY */
.image-gallery-bullet{
    @apply bg-grigio-chiaro-effe border-grigio-chiaro-effe !important;
}
.image-gallery-bullet.active{
    transform: unset !important;
    background-color: #00FFBA !important;
    border-color: #00FFBA !important;
}
.image-gallery-bullet:hover{
    background-color: #60DDC0 !important;
    border-color: #60DDC0 !important;
}

.image-gallery-bullets .image-gallery-bullet{
    box-shadow: unset !important;
}
#riepilogo-view .image-gallery-slide > div,
#overview-quote .image-gallery-slide > div{
    display:flex;
    align-items:center;
}
#riepilogo-view .image-gallery-slide .image-gallery-image,
#overview-quote .image-gallery-slide .image-gallery-image{
    max-height: 200px !important;
    object-fit: contain !important;
}
@media screen and (max-width: 768px){
    #overview-quote .image-gallery-slide .image-gallery-image,
    #riepilogo-view .image-gallery-slide .image-gallery-image{
        max-height: unset !important;
    }
}
@media screen and (min-width: 768px){
    #overview-quote .image-gallery-slide .image-gallery-image,
    #riepilogo-view .image-gallery-slide .image-gallery-image{
        padding: 15px 0;
    }
}

.img-wizard-container{
    opacity: 0;
}
.img-wizard-container.type-1,
.img-wizard-container.type-0{
    transition: all 1s ease;
    opacity: 1;
}


@-webkit-keyframes in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .arrow-gallery.active{
      opacity: 1;
  }
  .arrow-gallery.active:hover{
      cursor: pointer;
  }
  .arrow-gallery.disabled{
    opacity: .3;
}



@media print{
    #overview-quote .image-gallery-bullets{
        display: none;
    }
    #overview-quote .image-gallery-slides{
        display: flex !important;
    }
    #overview-quote .image-gallery-slide{
        position:relative !important;
        transform: unset !important;
    }
    #content-overview > div:first-child .image-gallery-image{
        width: 100%;
        height: 100%;
        max-height: unset !important;
        padding: 0 0 !important;
        object-fit: cover !important;
    }
    #content-overview > div:first-child .image-gallery-slides > div:first-child{
        display: block !important;
    }
    #content-overview > div:first-child .image-gallery-slides > div{
        display: none;
    }
}

.unit{
    color:rgba(58,58,58,.56);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
    padding-left: 8px;
}

.m-title{
    font-family: 'avertastd-bolduploaded_file' !important;
    text-transform: uppercase;
}

.menu-item span{
    font-size: 24px;
    font-family: "avertastd-lightuploaded_file" !important;
    text-transform: uppercase;
}
.wizard-btn{
    min-width: 170px;
}
.wizard-btn:hover span{
    @apply text-black-effe !important
}
.wizard-btn.active span{
    font-family: "avertastd-bolduploaded_file" !important;
}
.wizard-btn.disabled{
    opacity: .56;
}
.wizard-btn.disabled:hover{
    cursor: not-allowed;
}



/* .effe-async-paginate{
    transform: rotate(0deg);
    transition: all 2s;
}
.effe-async-paginate.effe-async-paginate-open{
    transform: rotate(90deg);
} */

#modal-configuratore-fullscreen{
    max-width: unset !important;
    margin: unset;
    padding:unset;
    height: 100vh;
}

#modal-configuratore-fullscreen .modal-content{
    border-radius: unset;
    border: unset;
    height: 100%;
}
.modal-content{
    border-radius: unset !important;
}
.modal-content{
    border: 1px solid #00FFBA !important;
}

.modal-gray{
    @apply bg-modal-effe !important;
}

.labelModalIntExt{
    opacity: 0;
    transition: all 1.5s;
}
.labelModalIntExt.interno,.labelModalIntExt.esterno{
    opacity: 1;
}


@keyframes toLoading {
    from {
        z-index: 99999;
        opacity: 1;
        backdrop-filter: blur(4px);
    }
    to {
        z-index: -9999;
        opacity: 0;
        backdrop-filter: blur(0);
    }
}

@keyframes showBg {
    from {
       background-color: transparent;
    }
    to {
        background-color: #2B2E34;
    }
}


.loader-effe{
    /* transition: all 1.5s; */
}
.loader-background{
    /* transition: all 1s; */
}

.loader-effe.loader-loading{
    z-index: 99999;
    opacity: 1;
    backdrop-filter: blur(4px);
}
.loader-effe.loader-loading .loader-background{
    -webkit-animation: showBg .1s linear;
    -moz-animation: showBg .1s linear;
    -o-animation: showBg .1s linear;
    animation: showBg .1s linear;
    -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;    /* FF 5+ */
    -o-animation-fill-mode: forwards;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards;     /* IE 10+ */
    animation-fill-mode: forwards;  
}
.loader-effe.loader-hidden{
    -webkit-animation: toLoading 1.5s ease-out;
    -moz-animation: toLoading 1.5s ease-out;
    -o-animation: toLoading 1.5s ease-out;
    animation: toLoading 1.5s ease-out;
    -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;    /* FF 5+ */
    -o-animation-fill-mode: forwards;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards;     /* IE 10+ */
    animation-fill-mode: forwards;  
}
/* .loader-effe.loader-hidden .loader-background{
    @apply bg-transparent;
} */




.pulse {
    animation: pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .loadable {
    overflow: hidden;
    margin: 0 auto;
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  }
  img {
    display: block;
  }
  
  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }
  

.header-app{
    transition: all .5s;
}
.header-app.onscroll{
    @apply bg-black-effe;
}
.header-app.ontop{
    @apply bg-transparent;
}

.textcell.secondary:hover hr{
    @apply bg-transparent !important;
}
.textcell.primary:hover hr{
    @apply bg-transparent !important;
}

.col-data:first-child{
    padding-right: 50px;
}
.col-data:last-child{
    padding-left: 50px;
}


@media screen and (max-width:320px){
    .text-menu *{
        font-size: 20px !important;
    }
    .logo-menu{
        width: 4rem;
    }
    .wizard-btn{
        min-width: 120px !important;
    }
}
@media screen and (max-width:496px){
    .wizard-btn{
        min-width: 150px;
    }
    .text-titleCard.action-selectable-grid-item{
        font-size: 14px !important;
    }
    #modal-configuratore,.modal-content,.modal-dialog{
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0;
    }
    .modal-content{
        border: 4px solid #00FFBA !important;
    }
}
@media screen and (max-width:576px){
    .container{
        max-width: 100% !important;
    }
    .col-content{
        padding: 0 !important;
    }
}
@media screen and (max-width:767px){
    .col-data{
        padding:0 !important;
    }
    .col-data:last-child{
        margin-bottom: 30px;
    }
}


/* ANIMATIONS */
button .icon-restart .circle,
button .icon-restart .vertline,
.action-btn .icon-arrow,
.action-btn .icon-arrow-close,
button .icon-copy path.back,
button .icon-copy .border,
button .icon-copy .stoke,
.arrow-gallery-forward.active,
.hamburger-menu > img,
button .icon-close-menu
{
    transition: all .3s;
}

.action-btn .icon-arrow{
    width: 15px;
}
.action-btn:hover .icon-arrow{
    width: 25px;
}
.action-btn .icon-arrow-close{
    transform: rotate(0deg);
}
.action-btn:hover .icon-arrow-close,
button:hover .icon-close-menu{
    transform: rotate(90deg);
}
button:hover .icon-restart .circle{
    fill: #00FFBA;
}
button:hover .icon-restart .vertline{
    fill: white;
}

button:disabled .icon-copy path.back{
    fill: transparent;
}
button:disabled .icon-copy .border{
    fill: #9A9A9A;
}
button:disabled .icon-copy .stoke{
    stroke: #9A9A9A;
}
button:not(:disabled) .icon-copy path.back{
    fill: transparent;
}
button:not(:disabled) .icon-copy .border{
    fill: #00FFBA;
}
button:not(:disabled) .icon-copy .stoke{
    stroke: #00FFBA;
}
button:hover:not(:disabled) .icon-copy path.back{
    fill: #00FFBA;
}
button:hover:not(:disabled) .icon-copy .border{
    fill: white;
}
button:hover:not(:disabled) .icon-copy .stoke{
    stroke: white;
}

.arrow-gallery-forward.active:hover path:first-child{
    transform: translate(-6151.946px, 25.06px);
}
.arrow-gallery-forward.active:hover{
    transform: translateX(10px);
}
.arrow-gallery-back.active:hover{
    transform: translateX(-10px);
}
.arrow-gallery-back.active:hover path:first-child{
    transform: translate(-6624.909px, -466px);
}
.hamburger-menu > img{
    transform: scaleX(1);
}
.hamburger-menu > img:hover{
    transform: scaleX(1.6);
}


/* PDF */
 .pdf-viewer > .row, .pdf-viewer .content-modal-wrapper, 
.pdf-viewer .content-modal-wrapper > div{
    height: 100%;
}
.pdf-viewer .effe-pdf{
    width: 100%;
    height: 90%;
}

.icon-download {
    transition: all .5s !important;
    transform: unset;
}
.icon-download:hover {
    transform: translateY(10px);
    stroke-width: 2;
}


.container-pdf{
    max-width: 1600px !important;
    margin:  0 auto;
}

@media print {
    #img-vano-print{

    }
}