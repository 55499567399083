
@font-face {
    font-family: "avertastd-blackuploaded_file";
    src: url("./../font/avertastd-black-webfont.woff2") format("woff2"),
      url("./../font/avertastd-black-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "avertastd-bolduploaded_file";
    src: url("./../font/avertastd-bold-webfont.woff2") format("woff2"),
      url("./../font/avertastd-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "avertastd-extrabouploadedfile";
    src: url("./../font/avertastd-extrabold-webfont.woff2") format("woff2"),
      url("./../font/avertastd-extrabold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "avertastd-semiboluploadedfile";
    src: url("./../font/avertastd-semibold-webfont.woff2") format("woff2"),
      url("./../font/avertastd-semibold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "avertastd-lightuploaded_file";
    src: url("./../font/avertastd-light-webfont.woff2") format("woff2"),
      url("./../font/avertastd-light-webfont.woff") format("woff");
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: "avertastd-regularuploadedfile";
    src: url("./../font/avertastd-regular-webfont.woff2") format("woff2"),
      url("./../font/avertastd-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "avertastd-extrathuploadedfile";
    src: url("./../font/avertastd-extrathin-webfont.woff2") format("woff2"),
      url("./../font/avertastd-extrathin-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "avertastd-thinuploaded_file";
    src: url("./../font/avertastd-thin-webfont.woff2") format("woff2"),
      url("./../font/avertastd-thin-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }